import bookLoadignlogo from '../../image/logoBax.svg'
import roudLoadingLogo from '../../image/roundLoadingLogo.svg'
import './loading_styles.css';
import ProgressBar from './ProgressBar';






export default function Loading() {
    return (
        <div className='loader'>
            <div className="icon">
                <img src={bookLoadignlogo} alt="" />
                <img src={roudLoadingLogo} alt="" />
            </div>
            <ProgressBar targetProgress={100}/>
        </div>
    )
}

