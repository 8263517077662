import Btns from "../../components/btns/Btns";
import { useContext, useEffect, useState } from "react";
import './yonalish.css'
import AppContext from "../../context/AppContext";
import axios from "axios";
import Footer from "../../components/footer/Footer";




export default function Sirtqi() {

    // btn set
    const [activeButton, setActiveButton] = useState(2);

    const buttons = [
        {
            id: 1,
            name_uz: 'Kunduzgi ta’lim yoʻnalishlari',
            name_ru: 'Очные направления образования',
            name_en: 'Full-time education programs',
            link: '/kunduzgi',
        },
        {
            id: 2,
            name_uz: 'Sirtqi taʻlim yoʻnalishlari',
            name_ru: 'Заочное направление оброзования',
            name_en: 'Correspondence courses of education',
            link: '/sirtqi',
        },
        {
            id: 5,
            name_uz: 'Onlayn ariza topshirish',
            name_ru: 'Онлайн подача документов',
            name_en: 'Online admission',
            link: 'https://qabul.utu-ranch.uz',
        }
    ]

    const data = [
        {
            "code": "60410500",
            "program": "Bank ishi va auditi",
            "price": "16 016 000"
        },
        {
            "code": "60110500",
            "program": "Boshlang'ich ta'lim",
            "price": "12 936 000"
        },
        {
            "code": "60410100",
            "program": "Buxgalteriya hisobi va audit (tarmoqlar va sohalar bo'yicha)",
            "price": "12 936 000"
        },
        {
            "code": "60710600",
            "program": "Elektr energetikasi (Tarmoqlar va yo'nalishlar bo'yicha)",
            "price": "14 560 000"
        },
        {
            "code": "60310100",
            "program": "Iqtisodiyot (tarmoqlar va sohalar bo'yicha)",
            "price": "12 936 000"
        },
        {
            "code": "60612100",
            "program": "Kiberxavfsizlik injiniringi",
            "price": "16 363 648"
        },
        {
            "code": "60610500",
            "program": "Kompyuter injiniringi (AT-Servis)",
            "price": "16 363 648"
        },
        {
            "code": "60610500",
            "program": "Kompyuter injiniringi (Axborot xavfsizligi)",
            "price": "16 363 648"
        },
        {
            "code": "60610500",
            "program": "Kompyuter injiniringi (Multimedia texnologiyalari)",
            "price": "16 363 648"
        },
        {
            "code": "60610500",
            "program": "Kompyuter injiniringi (Kompyuter injiniringi)",
            "price": "16 363 648"
        },
        {
            "code": "60110300",
            "program": "Maktabgacha ta'lim psixologiyasi va pedagogikasi",
            "price": "12 040 000"
        },
        {
            "code": "60412500",
            "program": "Marketing (tarmoqlar va sohalar bo'yicha)",
            "price": "12 936 000"
        },
        {
            "code": "60720800",
            "program": "Mashinasozlik texnologiyasi, mashinasozlik ishlab chiqarishini jixozlash va avtomatlashtirish",
            "price": "14 610 400"
        },
        {
            "code": "60411200",
            "program": "Menejment (tarmoqlar va sohalar bo'yicha)",
            "price": "12 936 000"
        },
        {
            "code": "61010300",
            "program": "Sport faoliyati (boks)",
            "price": "14 560 000"
        },
        {
            "code": "61010300",
            "program": "Sport faoliyati (erkin kurash)",
            "price": "14 560 000"
        },
        {
            "code": "61010300",
            "program": "Sport faoliyati (futbol)",
            "price": "14 560 000"
        },
        {
            "code": "61010300",
            "program": "Sport faoliyati (voleybol)",
            "price": "14 560 000"
        },
        {
            "code": "61010300",
            "program": "Sport faoliyati (yengil atletika)",
            "price": "14 560 000"
        },
        {
            "code": "61040100",
            "program": "Transport Logistikasi (Transport turlari bo'yicha)",
            "price": "14 560 000"
        },
        {
            "code": "60712500",
            "program": "Transport vositalari muhandisligi (Turlari bo'yicha)",
            "price": "14 560 000"
        }
    ]

    const [apiData, setApiData] = useState([])

    const getYonalish = async () => {
        const response = await axios.get(`https://new.utu-ranch.uz/category/yonalishlar-sirtqi/`);
        setApiData(response.data);
    }

    useEffect(() => {
        getYonalish()
    }, [])

    const { leng } = useContext(AppContext)


    return (
        <>
            <div className="yonalish">
                <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
                <div className="categoryYonlish">
                    <h2 className="categoryYonlish_title">{leng == 'uz' ? "Sirtqi taʻlim yoʻnalishlari" : leng == 'ru' ? "Направления заочного образования" : "Correspondence courses of education"}</h2>
                    <div className="categoryYonlish_lists">
                        <div className="categoryYonlish_lists_list">
                            <span>{leng == 'uz' ? "Yo'nalish kodi" : leng == 'ru' ? "Код направления" : "Direction code"}</span>
                            <span>{leng == 'uz' ? "Yo'nalishlar nomi" : leng == 'ru' ? "Hаправления" : "Transfer of learning"}</span>
                            <span>{leng == 'uz' ? "Shartnoma narxi" : leng == 'ru' ? "Сумма контракта" : "Contract amount"}</span>
                        </div>
                        {
                            apiData.map(item => (
                                <div className="categoryYonlish_lists_list">
                                    <span>{item.code}</span>
                                    <span>{item[`name_${leng}`]}</span>
                                    <span>{item.price} {leng == 'uz' ? "so'm" : leng == 'ru' ? 'сум' : "uzs"}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
                {
                    leng == 'uz' ? (
                        <div className="eslatma">
                            <p>Eslatma: Ikkinchi mutaxassislikga barcha yo'nalishlar bo'yicha 2 kursdan qabul qilinadi (2024-2025 o'quv yilida ochilgan yo'nalishlaridan tashqari).</p>
                            <p>*60110400 - Boshlang'ich ta'lim va 60111200 -Jismoniy madaniyat yo'nalishining sirtqi ta'lim shakliga hujjat topshirish uchun kamida 5 yillik pedagogik stajga ega bo'lish talab qilinadi. **60730900 - Shahar qurilish va loyihalash va 60730100 - Arxitektura yo'nalishlarning sirtqi ta'lim shakliga talabalar imtihon asosida qabul qilinadi</p>
                        </div>
                    ) : leng == 'ru' ? (
                        <div className="eslatma">
                            <p>ПРИМЕЧАНИЕ: На вторую специальность прием по всем направлениям осуществляется со второго курса.</p>
                            <p>- *60110400 – Для подачи документов на заочное направление обучения «Начальное образование», требуется как минимум, наличие пятилетнего педагогического стажа.</p>
                        </div>
                    ) :
                        (
                            <div className="eslatma">
                                <p>Note: Admission to the second specialty is from 2 courses in all areas (except for the areas opened in the 2024-2025 academic year).</p>
                                <p>*A minimum of 5 years of pedagogical experience is required to apply for the correspondence form of 60110400 - Primary Education and 60111200 - Physical Culture. **Students are admitted on an examination basis to the correspondence program in the areas of 60730900 - Urban Planning and Design and 60730100 - Architecture</p>
                            </div>
                        )
                }
            </div>
            <Footer />
        </>
    )
}   