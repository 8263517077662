import './struktura.css'
import struktura1 from './Struktura-1.png'
import struktura2 from './Struktura-2.png'
import Btns from '../../components/btns/Btns'
import { useState } from 'react';

export default function Struktura() {

    const [activeButton, setActiveButton] = useState(8);

    const buttons = [
        {
            id: 1,
            name_uz: 'Universitet haqida',
            name_ru: 'О университете',
            name_en: 'About University',
            link: '/about',
        },
        {
            id: 2,
            name_uz: 'Fakultetlar',
            name_ru: 'Факультеты',
            name_en: 'Faculties',
            link: '/department',
        },
        {
            id: 3,
            name_uz: 'Markaz va boʻlimlar',
            name_ru: 'Центры и Отделы',
            name_en: 'Centers and other departments',
            link: '/departments',
        },
        {
            id: 4,
            name_uz: 'Rektorat',
            name_ru: 'Ректорат',
            name_en: 'University Rectorate',
            link: '/rektorat',
        },
        {
            id: 5,
            name_uz: 'Universitet ichki mehnat tartib qoidalar',
            name_ru: 'Кодекс внутреннего трудового порядка университета',
            name_en: 'BEHAVIORAL CODE',
            link: '/rules',
        },
        {
            id: 6,
            name_uz: 'Universitet odob-axloq qoidalari',
            name_ru: 'Кодекс поведения',
            name_en: 'BEHAVIORAL CODE',
            link: '/rulesEtika',
        },
        {
            id: 7,
            name_uz: 'Universitet nizomi',
            name_ru: 'Устав Университета',
            name_en: 'University charter',
            link: '/regulation',
        },
        {
            id: 8,
            name_uz: "Universitet tashkiliy tuzilmasi",
            name_ru: "Cтруктура Университета",
            name_en: "Structure of the University",
            link: "/tashkiliytuzilmasi"
        }
    ]
    return (
        <>
            <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
            <div className="struktura">
                <img src={struktura1} alt="" />
                <img src={struktura2} alt="" />
            </div>
        </>
    )
}