import { useContext, useEffect, useState } from 'react'
import './qqk.css'
import axios from 'axios';
import DOMPurify from 'dompurify'
import { Link } from 'react-router-dom';
import AppContext from '../../context/AppContext';

export default function Qqk() {

    const [data, setData] = useState([]);
    const getData = async () => {
        const response = await axios.get(`https://new.utu-ranch.uz/news/maqolalar/`);
        setData(response.data)
    }

    useEffect(() => {
        getData()
    }, [])

    const {leng} = useContext(AppContext)

    return (
        <>
            <div className="qqk_con">
                <p className="qqkTtitle">{leng == 'uz' ? "Maqolalar" : leng == 'ru' ? "Статьи" : "Articles"}</p>
                <div className="qqkBoxs">
                    {
                        data.map(item => (
                            <Link to={`/qqk/${item.id}`}>
                                <div className="qqkBox">
                                    <p className="qqkBoxs_title">{item[`title_${leng}`]}</p>
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item[`desc_${leng}`].substring(0, 299) + "...") }}></span>
                                    <span>{item.created_at}</span>
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </>
    )
}