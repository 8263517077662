import Btns from "../../components/btns/Btns";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import Footer from "../../components/footer/Footer";
import axios from "axios";
// import './yonalish.css'




export default function Magister() {

    // btn set
    const [activeButton, setActiveButton] = useState(1);

    const buttons = [
        {
            id: 1,
            name_uz: "Magistratura",
            name_ru: 'Магистратура',
            name_en: "Master's degree",
            link: '/magistratura',
        },
    ]

    const [data , setData] = useState([])
    const getData = async () => {
        const response = await axios.get(`https://new.utu-ranch.uz/category/yonalishlar-magister/`);
        setData(response.data)
    } 
    useEffect(() => {
        getData()
    }, [])

    const { leng } = useContext(AppContext)

    return (
        <>
            <div className="yonalish">
                <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
                <div className="categoryYonlish">
                    <h2 className="categoryYonlish_title">{leng == 'uz' ? "Magistratura" : leng == 'ru' ? "Магистратура" : "Master's degree"}</h2>
                    <div className="categoryYonlish_lists">
                        <div className="categoryYonlish_lists_list">
                            <span>{leng == 'uz' ? "Yo'nalish kodi" : leng == 'ru' ? "Код направления" : "Direction code"}</span>
                            <span>{leng == 'uz' ? "Yo'nalishlar nomi" : leng == 'ru' ? "Hаправления" : "Transfer of learning"}</span>
                            <span>{leng == 'uz' ? "Shartnoma narxi" : leng == 'ru' ? "Сумма контракта" : "Contract amount"}</span>
                        </div>
                        {
                            data.map(item => (
                                <div className="categoryYonlish_lists_list">
                                    <span>{item.code}</span>
                                    <span>{item[`name_${leng}`]}</span>
                                    <span>{item.price} {leng == 'uz' ? "so'm" : leng == 'ru' ? 'сум' : "uzs"}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}   