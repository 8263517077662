import './news.css';
import Btns from "../../components/btns/Btns";
import "../../components/btns/btns_styles.css";
import { useContext, useEffect, useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import axios from 'axios';
import AppContext from '../../context/AppContext';

export default function NewsAll() {
    const [activeButton, setActiveButton] = useState(1);

    const buttons = [
        {
            id: 1,
            name_uz: 'Yangiliklar',
            name_ru: 'Новости',
            name_en: 'News',
            link: '/newsAll',
        },
        {
            id: 2,
            name_uz: 'Ommaviy tadbirlar',
            name_ru: 'Публичные мероприятия',
            name_en: 'Public Events',
            link: '/publicNews',
        },
        {
            id: 3,
            name_uz: 'Universitet',
            name_ru: 'Университет',
            name_en: 'University',
            link: '/newsUnversity',
        },
        {
            id: 4,
            name_uz: 'Xalqaro munosabatlar',
            name_ru: 'Международные отношения',
            name_en: 'International Relations',
            link: '/xalqaroNews',
        }
    ];

    const [newsData, setNewsData] = useState([]);
    const [page, setPage] = useState(1); // Start from the first page
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true); // Track if there are more pages to load
    const { leng } = useContext(AppContext);

    const fetchNewsData = async (pageNumber) => {
        try {
            setLoading(true);
            const response = await axios.get(`https://new.utu-ranch.uz/news/yangiliklar/?page=${pageNumber}`);
            const newResults = response.data.results;

            if (newResults.length === 0) {
                setHasMore(false); // No more data to load
            } else {
                setNewsData((prevData) => [...prevData, ...newResults]); // Append new data to existing data
            }
        } catch (error) {
            console.error("Error fetching news data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNewsData(page);
    }, [page]); // Fetch data whenever the page changes

    const loadMoreNews = () => {
        if (hasMore) {
            setPage((prevPage) => prevPage + 1); // Increment the page number
        }
    };

    return (
        <div className="newsAll">
            <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
            <p className="newsAll_title">
                {leng === 'uz' ? "Ko'proq yangiliklar" : leng === 'ru' ? "Больше новостей" : "More news"}
            </p>
            <div className="newsAll_main">
                {newsData.map((item) => (
                    <div className="moreNews_main_news" key={item.id}>
                        <img src={item.image} alt="" />
                        <Link to={`/news/${item.id}`}>
                            <p className="title">{item[`title_${leng}`]?.slice(0, 100)}...</p>
                        </Link>
                        <div className="date_view">
                            <p>{item.created_at}</p>
                            <span>
                                <IoEyeSharp /> 162
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            {hasMore && (
                <button className='moreNewsBtn' onClick={loadMoreNews} disabled={loading}>
                    {loading ? (
                        leng === 'uz' ? "Yuklanmoqda..." : leng === 'ru' ? "Загрузка..." : "Loading..."
                    ) : (
                        leng === 'uz' ? "Ko'proq" : leng === 'ru' ? "еще" : "More"
                    )}
                </button>
            )}
        </div>
    );
}
